import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Achievements from './achievements';
import AchievementProducts from './achievementProducts';
import Assets from './assets';
import AssetCategories from './assetCategories';
import ChatGpt from './chatgpt';
import Dashboard from './dashboard';
import Cards from './cards';
import CardJustificationReasons from './cardJustificationReasons';
import CardMapViews from './cardMapViews';
import CardTemplates from './cardTemplates';
import Clients from './clients';
import ClientContracts from './clientContracts';
import Departments from './departments';
import EconomicGroups from './economicGroups';
import Files from './files';
import FileCategories from './fileCategories';
import Newsletters from './newsletters';
import Notifications from './notifications';
import Users from './users';
import QueuedJobs from './queuedJobs';
import Rpa from './rpa';
import withAdminLayout from '../../layout/withAdminLayout';

const NotFound = lazy(() => import('../../container/pages/404'));
const Tools = lazy(() => import('../../container/pages/Tools'));
const Reports = lazy(() => import('../../container/pages/Reports'));

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route exact path={path} component={Dashboard} />
        <Route path={`${path}/chatgpt`} component={ChatGpt} />
        <Route path={`${path}/achievements`} component={Achievements} />
        <Route path={`${path}/achievementProducts`} component={AchievementProducts} />
        <Route path={`${path}/assets`} component={Assets} />
        <Route path={`${path}/assetCategories`} component={AssetCategories} />
        <Route path={`${path}/cards`} component={Cards} />
        <Route path={`${path}/cardJustificationReasons`} component={CardJustificationReasons} />
        <Route path={`${path}/cardMapViews`} component={CardMapViews} />
        <Route path={`${path}/cardTemplates`} component={CardTemplates} />
        <Route path={`${path}/clients`} component={Clients} />
        <Route path={`${path}/clientContracts`} component={ClientContracts} />
        <Route path={`${path}/departments`} component={Departments} />
        <Route path={`${path}/economicGroups`} component={EconomicGroups} />
        <Route path={`${path}/files`} component={Files} />
        <Route path={`${path}/fileCategories`} component={FileCategories} />
        <Route path={`${path}/newsletters`} component={Newsletters} />
        <Route path={`${path}/notifications`} component={Notifications} />
        <Route path={`${path}/users`} component={Users} />
        <Route path={`${path}/queuedJobs`} component={QueuedJobs} />
        <Route path={`${path}/tools`} component={Tools} />
        <Route path={`${path}/reports`} component={Reports} />
        <Route path={`${path}/rpa`} component={Rpa} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default withAdminLayout(Admin);
