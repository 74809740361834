const actions = {
  OPENAI_THREADS_UPDATE_BEGIN: 'OPENAI_THREADS_UPDATE_BEGIN',
  OPENAI_THREADS_UPDATE_SUCCESS: 'OPENAI_THREADS_UPDATE_SUCCESS',
  OPENAI_THREADS_UPDATE_ERR: 'OPENAI_THREADS_UPDATE_ERR',

  openaiThreadsUpdateBegin: () => {
    return {
      type: actions.OPENAI_THREADS_UPDATE_BEGIN,
    };
  },

  openaiThreadsUpdateSuccess: data => {
    return {
      type: actions.OPENAI_THREADS_UPDATE_SUCCESS,
      data,
    };
  },

  openaiThreadsUpdateErr: err => {
    return {
      type: actions.OPENAI_THREADS_UPDATE_ERR,
      err,
    };
  },

  OPENAI_THREADS_CLEANUP_BEGIN: 'OPENAI_THREADS_CLEANUP_BEGIN',
  OPENAI_THREADS_CLEANUP_SUCCESS: 'OPENAI_THREADS_CLEANUP_SUCCESS',
  OPENAI_THREADS_CLEANUP_ERR: 'OPENAI_THREADS_CLEANUP_ERR',

  openaiThreadsCleanupBegin: () => {
    return {
      type: actions.OPENAI_THREADS_CLEANUP_BEGIN,
    };
  },

  openaiThreadsCleanupSuccess: data => {
    return {
      type: actions.OPENAI_THREADS_CLEANUP_SUCCESS,
      data,
    };
  },

  openaiThreadsCleanupErr: err => {
    return {
      type: actions.OPENAI_THREADS_CLEANUP_ERR,
      err,
    };
  },

  OPENAI_THREAD_UPDATE_BEGIN: 'OPENAI_THREAD_UPDATE_BEGIN',
  OPENAI_THREAD_UPDATE_SUCCESS: 'OPENAI_THREAD_UPDATE_SUCCESS',
  OPENAI_THREAD_UPDATE_ERR: 'OPENAI_THREAD_UPDATE_ERR',

  openaiThreadUpdateBegin: () => {
    return {
      type: actions.OPENAI_THREAD_UPDATE_BEGIN,
    };
  },

  openaiThreadUpdateSuccess: data => {
    return {
      type: actions.OPENAI_THREAD_UPDATE_SUCCESS,
      data,
    };
  },

  openaiThreadUpdateErr: err => {
    return {
      type: actions.OPENAI_THREAD_UPDATE_ERR,
      err,
    };
  },

  OPENAI_THREAD_CLEANUP_BEGIN: 'OPENAI_THREAD_CLEANUP_BEGIN',
  OPENAI_THREAD_CLEANUP_SUCCESS: 'OPENAI_THREAD_CLEANUP_SUCCESS',
  OPENAI_THREAD_CLEANUP_ERR: 'OPENAI_THREAD_CLEANUP_ERR',

  openaiThreadCleanupBegin: () => {
    return {
      type: actions.OPENAI_THREAD_CLEANUP_BEGIN,
    };
  },

  openaiThreadCleanupSuccess: () => {
    return {
      type: actions.OPENAI_THREAD_CLEANUP_SUCCESS,
    };
  },

  openaiThreadCleanupErr: err => {
    return {
      type: actions.OPENAI_THREAD_CLEANUP_ERR,
      err,
    };
  },

  OPENAI_THREAD_CREATE_BEGIN: 'OPENAI_THREAD_CREATE_BEGIN',
  OPENAI_THREAD_CREATE_SUCCESS: 'OPENAI_THREAD_CREATE_SUCCESS',
  OPENAI_THREAD_CREATE_ERR: 'OPENAI_THREAD_CREATE_ERR',

  openaiThreadCreateBegin: () => {
    return {
      type: actions.OPENAI_THREAD_CREATE_BEGIN,
    };
  },

  openaiThreadCreateSuccess: data => {
    return {
      type: actions.OPENAI_THREAD_CREATE_SUCCESS,
      data,
    };
  },

  openaiThreadCreateErr: err => {
    return {
      type: actions.OPENAI_THREAD_CREATE_ERR,
      err,
    };
  },

  OPENAI_THREAD_MESSAGE_BEGIN: 'OPENAI_THREAD_MESSAGE_BEGIN',
  OPENAI_THREAD_MESSAGE_SUCCESS: 'OPENAI_THREAD_MESSAGE_SUCCESS',
  OPENAI_THREAD_MESSAGE_ERR: 'OPENAI_THREAD_MESSAGE_ERR',

  openaiThreadMessageBegin: () => {
    return {
      type: actions.OPENAI_THREAD_MESSAGE_BEGIN,
    };
  },

  openaiThreadMessageSuccess: data => {
    return {
      type: actions.OPENAI_THREAD_MESSAGE_SUCCESS,
      data,
    };
  },

  openaiThreadMessageErr: err => {
    return {
      type: actions.OPENAI_THREAD_MESSAGE_ERR,
      err,
    };
  },

  OPENAI_THREAD_DELETE_BEGIN: 'OPENAI_THREAD_DELETE_BEGIN',
  OPENAI_THREAD_DELETE_SUCCESS: 'OPENAI_THREAD_DELETE_SUCCESS',
  OPENAI_THREAD_DELETE_ERR: 'OPENAI_THREAD_DELETE_ERR',

  openaiThreadDeleteBegin: () => {
    return {
      type: actions.OPENAI_THREAD_DELETE_BEGIN,
    };
  },

  openaiThreadDeleteSuccess: data => {
    return {
      type: actions.OPENAI_THREAD_DELETE_SUCCESS,
      data,
    };
  },

  openaiThreadDeleteErr: err => {
    return {
      type: actions.OPENAI_THREAD_DELETE_ERR,
      err,
    };
  },
};

export default actions;
