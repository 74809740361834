import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const ChatGpt = lazy(() => import('../../container/chatgpt/ChatApp'));

const ChatGptRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={ChatGpt} />
      <Route path={`${path}/add`} component={ChatGpt} />
      <Route path={`${path}/view/:id`} component={ChatGpt} />
    </Switch>
  );
};

export default ChatGptRoutes;
