const actions = {
  OPENAI_ASSISTANTS_LIST_UPDATE_BEGIN: 'OPENAI_ASSISTANTS_LIST_UPDATE_BEGIN',
  OPENAI_ASSISTANTS_LIST_UPDATE_SUCCESS: 'OPENAI_ASSISTANTS_LIST_UPDATE_SUCCESS',
  OPENAI_ASSISTANTS_LIST_UPDATE_ERR: 'OPENAI_ASSISTANTS_LIST_UPDATE_ERR',

  openaiAssistantsListUpdateBegin: () => {
    return {
      type: actions.OPENAI_ASSISTANTS_LIST_UPDATE_BEGIN,
    };
  },

  openaiAssistantsListUpdateSuccess: data => {
    return {
      type: actions.OPENAI_ASSISTANTS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  openaiAssistantsListUpdateErr: err => {
    return {
      type: actions.OPENAI_ASSISTANTS_LIST_UPDATE_ERR,
      err,
    };
  },

  OPENAI_ASSISTANTS_LIST_CLEANUP_BEGIN: 'OPENAI_ASSISTANTS_LIST_CLEANUP_BEGIN',
  OPENAI_ASSISTANTS_LIST_CLEANUP_SUCCESS: 'OPENAI_ASSISTANTS_LIST_CLEANUP_SUCCESS',
  OPENAI_ASSISTANTS_LIST_CLEANUP_ERR: 'OPENAI_ASSISTANTS_LIST_CLEANUP_ERR',

  openaiAssistantsListCleanupBegin: () => {
    return {
      type: actions.OPENAI_ASSISTANTS_LIST_CLEANUP_BEGIN,
    };
  },

  openaiAssistantsListCleanupSuccess: () => {
    return {
      type: actions.OPENAI_ASSISTANTS_LIST_CLEANUP_SUCCESS,
    };
  },

  openaiAssistantsListCleanupErr: err => {
    return {
      type: actions.OPENAI_ASSISTANTS_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
