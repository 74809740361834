import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  deleting: false,
  error: null,
};

const {
  OPENAI_THREADS_UPDATE_BEGIN,
  OPENAI_THREADS_UPDATE_SUCCESS,
  OPENAI_THREADS_UPDATE_ERR,

  OPENAI_THREADS_CLEANUP_BEGIN,
  OPENAI_THREADS_CLEANUP_SUCCESS,
  OPENAI_THREADS_CLEANUP_ERR,

  OPENAI_THREAD_DELETE_BEGIN,
  OPENAI_THREAD_DELETE_SUCCESS,
  OPENAI_THREAD_DELETE_ERR,
} = actions;

const openaiThreadsReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case OPENAI_THREADS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case OPENAI_THREADS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case OPENAI_THREADS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case OPENAI_THREADS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case OPENAI_THREADS_CLEANUP_SUCCESS:
      return initialState;

    case OPENAI_THREADS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case OPENAI_THREAD_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case OPENAI_THREAD_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case OPENAI_THREAD_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  messages: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  OPENAI_THREAD_UPDATE_BEGIN,
  OPENAI_THREAD_UPDATE_SUCCESS,
  OPENAI_THREAD_UPDATE_ERR,

  OPENAI_THREAD_CLEANUP_BEGIN,
  OPENAI_THREAD_CLEANUP_SUCCESS,
  OPENAI_THREAD_CLEANUP_ERR,

  OPENAI_THREAD_CREATE_BEGIN,
  OPENAI_THREAD_CREATE_SUCCESS,
  OPENAI_THREAD_CREATE_ERR,

  OPENAI_THREAD_MESSAGE_BEGIN,
  OPENAI_THREAD_MESSAGE_SUCCESS,
  OPENAI_THREAD_MESSAGE_ERR,
} = actions;

const openaiThreadReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case OPENAI_THREAD_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case OPENAI_THREAD_UPDATE_SUCCESS:
      return {
        ...state,
        data: data.thread,
        messages: data.messages,
        loading: false,
      };

    case OPENAI_THREAD_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case OPENAI_THREAD_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case OPENAI_THREAD_CLEANUP_SUCCESS:
      return initialSingleState;

    case OPENAI_THREAD_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case OPENAI_THREAD_CREATE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case OPENAI_THREAD_CREATE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case OPENAI_THREAD_CREATE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    case OPENAI_THREAD_MESSAGE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case OPENAI_THREAD_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: data.messages,
        saving: false,
      };

    case OPENAI_THREAD_MESSAGE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { openaiThreadsReducer, openaiThreadReducer };
